import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import Header from "../components/header"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "../components/slider"
import About from "../components/about"
import Players from "../components/players"
import Companies from "../components/companies"
import Contact from "../components/contact"

const IndexPage = () => {
  const intl = useIntl()

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "slogan" })} />
      <div className="active-dark">
        <Header homeLink="/" logo="symbol-dark" />
        <Slider />
        <About />
        <Players />
        <Companies />
        <Contact />
      </div>
    </Layout>
  )
}

export default IndexPage
