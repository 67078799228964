import React from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const imageQuery = graphql`
  query {
    playersImage: file(relativePath: { eq: "players-and-family.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const ListItem = ({ text }) => (
  <li>
    <svg
      stroke="currentColor"
      fill="none"
      strokeWidth="2"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline points="20 6 9 17 4 12"></polyline>
    </svg>
    {text}
  </li>
)

const Players = () => {
  const { playersImage } = useStaticQuery(imageQuery)
  const intl = useIntl()

  return (
    <div id="players" className="fix">
      <div className="call-to-action-wrapper call-to-action text-white-wrapper ptb--120 bg_image bg_image--players">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner text-center">
                <h2>
                  <FormattedMessage id="session.players.title" />
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="players-area ptb--120 bg_color--6">
        <div className="players-wrapper">
          <div className="container">
            <div className="row row--35">
              <div className="col-lg-7">
                <div className="players-inner inner">
                  <div className="section-title">
                    <h3 className="title">
                      <FormattedMessage id="players.title" />
                    </h3>
                    <p className="description">
                      <FormattedMessage id="players.description_0" />
                    </p>
                    <p className="description">
                      <FormattedMessage id="players.description_1" />
                    </p>
                  </div>
                  <div className="mt--30">
                    <h4>
                      <FormattedMessage id="players.subtitle_0" />
                    </h4>
                    <ul className="list-style--1">
                      {[...Array(7)].map((_, idx) => (
                        <ListItem
                          key={`players.topic_0_${idx}`}
                          text={intl.formatMessage({
                            id: `players.topic_0_${idx}`,
                          })}
                        />
                      ))}
                    </ul>
                  </div>
                  <div className="mt--30">
                    <h4>
                      <FormattedMessage id="players.subtitle_1" />
                    </h4>
                    <ul className="list-style--1">
                      {[...Array(4)].map((_, idx) => (
                        <ListItem
                          key={`players.topic_1_${idx}`}
                          text={intl.formatMessage({
                            id: `players.topic_1_${idx}`,
                          })}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="thumbnail">
                  <Img fluid={playersImage.childImageSharp.fluid} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Players
