import React from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { FormattedMessage } from "gatsby-plugin-intl"

const tabs = [
  { key: "goals", descriptions: 2 },
  { key: "experience", descriptions: 4 },
  { key: "education", descriptions: 3 },
]

const AboutTabs = () => (
  <div>
    <div className="tabs-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <Tabs>
              <TabList className="tab-style--1">
                {tabs.map(tab => (
                  <Tab key={`tab-${tab.key}`}>
                    <FormattedMessage id={`tab.${tab.key}.title`} />
                  </Tab>
                ))}
              </TabList>

              {tabs.map(tab => (
                <TabPanel key={`tab-panel-${tab.key}`}>
                  <div className="single-tab-content">
                    <ul>
                      {[...Array(tab.descriptions)].map((_, idx) => (
                        <li key={`tab-${tab.key}-description-${idx}`}>
                          <FormattedMessage
                            id={`tab.${tab.key}.descriptions_${idx}`}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </TabPanel>
              ))}
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default AboutTabs
