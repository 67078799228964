import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const imageQuery = graphql`
  query {
    companiesImage: file(relativePath: { eq: "strategy-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Companies = () => {
  const { companiesImage } = useStaticQuery(imageQuery)

  return (
    <div id="companies" className="fix">
      <div className="call-to-action-wrapper call-to-action text-white-wrapper ptb--120 bg_image bg_image--clubs">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner text-center">
                <h2>
                  <FormattedMessage id="session.companies.title" />
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="companies-area ptb--120 bg_color--6">
        <div className="companies-wrapper">
          <div className="container">
            <div className="row row--35">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <Img fluid={companiesImage.childImageSharp.fluid} alt="" />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="companies-inner inner">
                  <div className="section-title">
                    <h3 className="title">
                      <FormattedMessage id="companies.title" />
                    </h3>
                    <p className="description">
                      <FormattedMessage id="companies.description_0" />
                    </p>
                    <p className="description">
                      <FormattedMessage id="companies.description_1" />
                    </p>
                    <p className="description">
                      <FormattedMessage id="companies.description_2" />
                    </p>
                    <p className="description">
                      <FormattedMessage id="companies.description_3" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Companies
