import React, { useState } from "react"
import { FiPhone, FiMail } from "react-icons/fi"
import { Toast } from "react-bootstrap"
import { FormattedMessage, useIntl } from "react-intl"

const CONTACT_FORM_URL =
  "https://wnvfp3exri.execute-api.us-east-1.amazonaws.com/production/contact_form"

const EMAIL_FORMAT = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

const INITIAL_STATE = {
  email: "",
  message: "",
}

const Contact = () => {
  const intl = useIntl()
  const [form, setForm] = useState(INITIAL_STATE)
  const [toastVisible, setToastVisible] = useState(false)

  const changeField = changes => setForm({ ...form, ...changes })

  const validForm = () => form.email.match(EMAIL_FORMAT)

  const sendForm = async e => {
    e.preventDefault()

    if (!validForm()) return

    await fetch(CONTACT_FORM_URL, {
      method: "POST",
      body: JSON.stringify(form),
    })

    setForm(INITIAL_STATE)
    setToastVisible(true)
  }

  return (
    <div id="contact" className="fix">
      <div className="rn-contact-area ptb--120 bg_color--1">
        <div className="contact-form--1">
          <div className="container">
            <div className="row row--35 align-items-start">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="section-title text-left mb--50">
                  <h2 className="title">
                    <FormattedMessage id="contact.title" />
                  </h2>
                  <ul className="description">
                    <li>
                      <FiPhone />
                      <a href="tel:+34647997582">+34 647997582</a>
                    </li>
                    <li>
                      <FiMail />
                      <a href="mailto:marcosmartinsm3@gmail.com">
                        marcosmartinsm3@gmail.com
                      </a>
                    </li>
                  </ul>
                  <p className="description">
                    <FormattedMessage id="contact.description" />
                  </p>
                </div>
                <div className="form-wrapper">
                  <form onSubmit={sendForm}>
                    <label htmlFor="item02">
                      <input
                        type="email"
                        name="email"
                        id="item02"
                        value={form.email}
                        onChange={e => {
                          changeField({ email: e.target.value })
                        }}
                        placeholder={intl.formatMessage({
                          id: "contact.form.email",
                        })}
                      />
                    </label>
                    <label htmlFor="item04">
                      <textarea
                        type="text"
                        id="item04"
                        name="message"
                        value={form.message}
                        onChange={e => {
                          changeField({ message: e.target.value })
                        }}
                        placeholder={intl.formatMessage({
                          id: "contact.form.message",
                        })}
                      />
                    </label>
                    <button
                      className="rn-button-style--2 btn-solid"
                      type="submit"
                      value="submit"
                      name="submit"
                      id="mc-embedded-subscribe"
                    >
                      <FormattedMessage id="contact.form.submit" />
                    </button>
                  </form>
                  <Toast
                    onClose={() => setToastVisible(false)}
                    show={toastVisible}
                    delay={3000}
                    autohide
                    className="mt--10"
                  >
                    <Toast.Body>
                      <FormattedMessage id="contact.form.success" />
                    </Toast.Body>
                  </Toast>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
