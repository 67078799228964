import React, { Component } from "react"
import { FaInstagram, FaFacebookF } from "react-icons/fa"
import { DropdownButton, Dropdown } from "react-bootstrap"
import { FiX, FiMenu } from "react-icons/fi"
import Scrollspy from "react-scrollspy"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby-plugin-intl"

const imageQuery = graphql`
  query {
    logo: file(relativePath: { eq: "m3-icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/santos.martins1" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/marquinhosbcn/" },
]

const menuItems = ["about", "players", "companies", "contact"]

const Logo = () => {
  const { logo } = useStaticQuery(imageQuery)

  return (
    <div className="logo">
      <Link to="/">
        <Img fluid={logo.childImageSharp.fluid} alt="Sports M3" />
      </Link>
    </div>
  )
}

const LanguageSelector = () => {
  const intl = useIntl()

  return (
    <DropdownButton
      className="language-selector"
      title={intl.locale.toUpperCase()}
    >
      <Dropdown.Item as="a" href="/es">
        Español
      </Dropdown.Item>
      <Dropdown.Item as="a" href="/pt">
        Português
      </Dropdown.Item>
    </DropdownButton>
  )
}

class Header extends Component {
  constructor(props) {
    super(props)
    this.menuTrigger = this.menuTrigger.bind(this)
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this)
    this.stickyHeader = this.stickyHeader.bind(this)
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open")
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open")
  }

  stickyHeader() {}

  componentDidMount() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky")
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky")
      }
    })

    var elements = document.querySelectorAll(".has-droupdown > a")
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active")
          this.classList.toggle("open")
        }
      }
    }
  }

  render() {
    return (
      <header
        id="home"
        className="header-area header-style-two header--fixed color-black"
      >
        <div className="header-wrapper">
          <div className="header-left d-flex align-items-center">
            <Logo />
            <nav className="mainmenunav d-lg-block ml--50">
              <Scrollspy
                className="mainmenu"
                items={menuItems}
                currentClassName="is-current"
                offset={-200}
              >
                {menuItems.map(item => (
                  <li key={item}>
                    <a href={`#${item}`}>
                      <FormattedMessage id={`menu.${item}`} />
                    </a>
                  </li>
                ))}
              </Scrollspy>
            </nav>
          </div>
          <div className="header-right">
            <div className="social-share-inner">
              <ul className="social-share social-style--2 color-black d-flex justify-content-start liststyle">
                {SocialShare.map((val, i) => (
                  <li key={i}>
                    <a
                      href={`${val.link}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {val.Social}
                    </a>
                  </li>
                ))}
                <li>
                  <LanguageSelector />
                </li>
              </ul>
            </div>
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    )
  }
}
export default Header
