import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import AboutTabs from "./aboutTabs"

const imageQuery = graphql`
  query {
    aboutMarcosMartins: file(relativePath: { eq: "about-marcos-martins-2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const About = () => {
  const { aboutMarcosMartins } = useStaticQuery(imageQuery)

  return (
    <div id="about" className="fix">
      <div className="about-area ptb--120  bg_color--1">
        <div className="about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-5">
                <div className="thumbnail">
                  <Img
                    fluid={aboutMarcosMartins.childImageSharp.fluid}
                    alt="Marcos Martins"
                  />
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about-inner inner">
                  <div className="section-title">
                    <h2 className="title">
                      <FormattedMessage id="about.title" />
                    </h2>
                    <p className="description">
                      <FormattedMessage id="about.description" />
                    </p>
                  </div>
                  <div className="row mt--30">
                    <AboutTabs />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
