import React from "react"
import { FormattedMessage } from "gatsby-plugin-intl"
import TextLoop from "react-text-loop"

const services = ["personalCoach", "sportsMarketing", "strategicConsult"]

const Slider = () => (
  <div className="active-dark">
    <div id="home" className="fix">
      <div className="slider-wrapper">
        <div className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--header">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-left">
                  <span>
                    <FormattedMessage id="slogan" />
                  </span>
                  <h1 className="title">
                    <FormattedMessage id="presentation" /> <br />
                    <TextLoop className="services">
                      {services.map(service => (
                        <span key={service}>
                          {" "}
                          <FormattedMessage id={`services.${service}`} />
                        </span>
                      ))}
                    </TextLoop>{" "}
                  </h1>
                  <h2>
                    <FormattedMessage id="subtitle" />
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Slider
